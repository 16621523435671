import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon } from "react-feather";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import OperationElementEntries from "./OperationElementEntries";
import _ from "lodash";

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1,
    '&:hover': {
      // borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },

  content: {
    margin: '8px 0',

    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '8px 16px',
  },
})(MuiAccordionDetails);

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;

export default function OperationElement(props: any) {
  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  const [title, setTitle] = useState<string>(getFormValue('title'));

  const refreshTitle = useCallback(_.debounce((v) => {
    setTitle(v);
  }, 500), []);

  return (
    <Accordion elevation={0} style={{ border: '1px solid rgba(0, 0, 0, 0.23)' }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <TextField
                label="Nazwa elementu"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                defaultValue={getFormValue('title')}
                onChange={(e) => {
                  setFormValue('title', e.target.value);
                  refreshTitle(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container item >
            <Grid item xs={12} >
              <Typography variant="subtitle1">Wpisy</Typography>
            </Grid>

            <Grid item xs={12} >
              <OperationElementEntries
                entries={props.data.entries || []}
                formKey={`${props.formKey}.entries`}
                setFormValue={props.setFormValue}
                getFormValue={props.getFormValue}
              />
            </Grid>

            <Grid item xs={12} >
              <MuiButton
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue('entries', 'append');
                }}
                size="small"
              >
                Nowy wpis
              </MuiButton>
            </Grid>

          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}