import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid, IconButton, FormControlLabel, MenuItem } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon, Trash as DeleteIcon } from "react-feather";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import _ from "lodash";
import { Checkbox } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1,
    '&:hover': {
      // borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },

  content: {
    margin: '8px 0',

    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '8px 16px',
  },
})(MuiAccordionDetails);

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;



const ElementRequiredCheckbox = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(!!(getFormValue('required')));

  return (
    <Checkbox
      name="checkedB"
      color="primary"
      checked={value}
      onChange={(e) => {
        setFormValue('required', e.target.checked);
        setValue(e.target.checked);
      }}
    />
  );
};


const TypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('type') || 10);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Typ"
      value={value}
      onChange={(e) => {
        setFormValue('type', e.target.value);
        setValue(e.target.value);
      }}
    >
      <MenuItem value={10}>Prawda/fałsz</MenuItem>
      <MenuItem value={20}>Tekst</MenuItem>
    </Select>
  );
};


const ValidatorTypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('validatorType') || 1);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Walidator"
      value={value}
      onChange={(e) => {
        setFormValue('validatorType', e.target.value);
        setValue(e.target.value);
      }}
      defaultValue={'null'}
    >
      <MenuItem value={'null'}>Brak</MenuItem>
      <MenuItem value={1}>Wartość</MenuItem>
      <MenuItem value={2}>Zakres</MenuItem>
      <MenuItem value={3}>Regex</MenuItem>
    </Select>
  );
};


function FormElement(props: any) {
  const [expanded, setExpanded] = useState<boolean>(props.expanded || false);
  const [contentVisible, setContentVisible] = useState<boolean>(expanded);

  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  const [title, setTitle] = useState<string>(getFormValue('title'));

  const refreshTitle = useCallback(_.debounce((v) => {
    setTitle(v);
  }, 150), []);

  return (
    <Accordion
      elevation={1}
      expanded={expanded}
      onChange={(e, expanded) => {
        setExpanded(expanded);
        expanded && setContentVisible(expanded);
      }}
      TransitionProps={{
        onExited: () => { setContentVisible(false); }
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
        <MuiButton
          aria-label="Acknowledge"
          onClick={(e: any) => {
            e.stopPropagation();
            setFormValue('root', 'remove');
          }}
          onFocus={(e: any) => e.stopPropagation()}
          component="button"
          size="small"
          color="secondary"
          variant="contained"
          style={{
            width: 30,
            height: 30,
            minWidth: 0,
            borderRadius: '50%',
            padding: 0,
            marginLeft: 'auto'
          }}
        >
          <DeleteIcon size={18} />
        </MuiButton>
      </AccordionSummary>

      <AccordionDetails>
        {contentVisible && (
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item xs={4} lg={2}>
                <TextField
                  label="Nazwa"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  defaultValue={getFormValue('title')}
                  onChange={(e) => {
                    setFormValue('title', e.target.value);
                    refreshTitle(e.target.value);
                  }}
                />
              </Grid>

              <Grid item lg={2}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                  <TypeSelect
                    getFormValue={getFormValue}
                    setFormValue={setFormValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} lg={"auto"}>
                <FormControlLabel
                  control={
                    <ElementRequiredCheckbox
                      getFormValue={getFormValue}
                      setFormValue={setFormValue}
                    />
                  }
                  label="Wymagany"
                  title="Tak"
                />
              </Grid>

            </Grid>

            <Grid container item spacing={3}>
              <Grid item lg={2}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Walidator</InputLabel>
                  <ValidatorTypeSelect
                    getFormValue={getFormValue}
                    setFormValue={setFormValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} lg={2}>
                <TextField
                  label="Walidator - wartość"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  defaultValue={getFormValue('validatorValue')}
                  onChange={(e) => {
                    setFormValue('validatorValue', e.target.value);
                  }}
                />
              </Grid>

            </Grid>

          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(FormElement);