import { useLayoutEffect } from "react";
import { useUIContext } from "../context/UIContext";

type MainRouteProps = {
  path: string;
  component: React.ReactNode;
};

export default function MainRoute(props: MainRouteProps) {
  const { actions } = useUIContext();

  useLayoutEffect(() => {
    if (props.path == '/auth/login') {
      actions.setLayout('default');
    } else {
      actions.setLayout('panel');
    }
  }, []);

  return <>{props.component}</>;
}