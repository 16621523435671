import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link, navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon, Trash as TrashIcon } from "react-feather";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import OperationsList from "../../components/Card/OperationsList";
import { useForm, useFormContext } from "react-hook-form";
import _, { conformsTo } from "lodash";
import FormElementsList from "../../components/Form/FormElementsList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 8 }}>{children}</Box>
      )}
    </div>
  );
}


const NavigationLink = React.forwardRef((props: any, ref) => {
  return (
    <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
  );
});

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1,
    '&:hover': {
      // borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },

  content: {
    margin: '8px 0',

    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '8px 16px',
  },
})(MuiAccordionDetails);

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;

const generateElementData = () => {
  return {
    title: 'element',
    type: 10,
    validatorType: 0,
    required: true,
    new: true
  };
};

async function saveData(data: any) {
  if (Array.isArray(data)) {
    data = {};
  }

  // Default options are marked with *
  const response = await fetch(process.env.REACT_APP_API_URL + '/db/forms', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });

  return response.json(); // parses JSON response into native JavaScript objects
}


async function saveSingleForm(data: any) {
  if (!data || !data.title) {
    return;
  }

  var currentData = await fetchData();

  if (!currentData || typeof currentData != 'object') {
    currentData = {};
  }

  currentData[data.title] = data;

  return await saveData(currentData);
}

async function fetchData() {
  // Default options are marked with *
  const response = await fetch(process.env.REACT_APP_API_URL + '/db/forms', {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });

  return response.json(); // parses JSON response into native JavaScript objects
}

async function delCard(titleToDelete: string) {
  var currentData = await fetchData();

  currentData = _.filter(currentData, (record, title) => {
    return title != titleToDelete;
  });
  currentData = _.filter(currentData, a => a.title);
  currentData = _.keyBy(currentData, 'title');

  return await saveData(currentData);
}

const MainForm = (props: any) => {
  const [data, setData] = useState<any>(props.defaultValues);
  const [inProgress, setInProgress] = useState(false);
  const form = useForm();

  useEffect(() => {
    form.register('title');

    // Register operations
    (data.elements || []).forEach((element: any, elementIndex: Number) => {
      form.register(`elements[${elementIndex}].title`);
      form.register(`elements[${elementIndex}].type`);
      form.register(`elements[${elementIndex}].required`);
      form.register(`elements[${elementIndex}].validatorType`);
      form.register(`elements[${elementIndex}].validatorValue`);
    });

  }, [form]);

  const onSubmit = async (data: any) => {
    setInProgress(true);

    if (data.elements) {
      data.elements = data.elements.filter((element: any) => {
        return element && element.title;
      });
    }

    try {
      await saveSingleForm(data);
    } catch (e) {
      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const getFormValue = (key: string) => {
    return form.getValues(key) ?? _.get(data, key);
  };

  const setFormValue = (key: string, value: any) => {
    if (value == 'append') {
      let arr = _.get(data, key, []);
      arr.push({ entries: [] });
      _.set(data, key, arr);

      setData({ ...data });
    } else if (value == 'remove') {
      key = key.replace('.root', '');
      _.set(data, key, undefined);
      form.unregister(key + '.title');
      form.unregister(key + '.type');
      form.unregister(key + '.required');
      form.unregister(key + '.validatorType');
      form.unregister(key + '.validatorValue');

      data.elements = _.filter(data && data.elements ? data.elements : []);
      setData({ ...data });
    } else {
      form.setValue(key, value);
    }
  };


  const params = useParams();

  // Fetch current data
  useEffect(() => {
    if (!params || !params.name) {
      return;
    }

    let isMounted = true;

    (async () => {
      try {
        const d = await fetchData();

        if (isMounted) {
          setData({ ...d[params.name] });
          form.reset({ ...d[params.name] }, {});
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => { isMounted = false; };
  }, []);

  const deleteCard = async () => {
    try {
      await delCard(params.name);
      navigate('/forms');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} >

      <Typography variant="h6" style={{ fontWeight: 400 }}>Ogólne</Typography>

      <Paper style={{ padding: 8 }} elevation={1}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <TextField
                label="Nazwa formularza"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={getFormValue('title')}
                key={getFormValue('title')}
                onChange={(e) => {
                  form.setValue("title", e.target.value);
                }}
              />
            </Grid>

          </Grid>
        </Grid>

      </Paper>

      <Typography variant="h6" style={{ marginTop: 16, fontWeight: 400 }}>Elementy</Typography>

      <FormElementsList
        elements={data.elements || []}
        getFormValue={getFormValue}
        setFormValue={setFormValue}
      />

      <MuiButton
        variant="contained"
        color="primary"
        startIcon={<PlusIcon>send</PlusIcon>}
        type="button"
        size="small"
        onClick={(e) => {
          e.preventDefault();

          data.elements = data.elements || [];
          data.elements.push(generateElementData());

          data.title = getFormValue('title');

          setData({ ...data });
          form.reset({ ...data }, {});
        }}
      >
        Nowy element
      </MuiButton>

      <Divider style={{ marginTop: 24, marginBottom: 8 }} />

      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon size={22} />}
        type="submit"
        inProgress={inProgress}
      >
        Zapisz formularz
      </Button>

      {(params && params.name) && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<TrashIcon size={22} />}
          type="button"
          style={{ marginLeft: 16 }}
          onClick={(e) => {
            e.preventDefault();

            deleteCard();
          }}
        >
          Usuń formularz
        </Button>
      )}

    </form>
  );
};

export default function FormsCreate(props: any) {

  return (
    <>
      <Breadcrumbs title="Nowy formularz" />
      <MainForm defaultValues={{
        title: '',
        elements: []
      }} />
    </>
  );
}