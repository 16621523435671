import React, { Component, ReactFragment, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { CheckSquare, Home, IconProps, Menu, Book } from 'react-feather';
import { Divider, IconButton } from '@material-ui/core';
import { useUIContext } from '../context/UIContext';
import { UrlObject } from 'url';
import { Link } from '@reach/router';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: drawerWidth,
    borderRight: 0
  },

  sidebarMainLink: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
    '&.isActive': {
      background: 'rgba(0, 0, 0, 0.20)',
    }
  },

  sidebarMainLinkText: {
    color: 'rgb(238, 238, 238)',
    fontSize: '13px'
  },

  sidebarMainLinkIcon: {
    color: 'rgba(238, 238, 238, 0.5)',
    minWidth: '40px'
  },

  sidebarDivider: {
    background: 'rgba(247, 249, 252, 0.15)'
  },

  logoWithMenu: {
    color: 'rgba(238, 238, 238, 0.5)',
    paddingLeft: '20px',
    paddingRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center'
  },

  logoWithMenu__Link: {
    color: 'rgb(238, 238, 238)',
    fontSize: '16px',
    marginLeft: '8px'
  }

}), { name: "sidebar" });

const closeNavbar = (closeAction: Function) => (
  event: React.KeyboardEvent | React.MouseEvent,
) => {

  if (
    event.type === 'keydown' &&
    ((event as React.KeyboardEvent).key === 'Tab' ||
      (event as React.KeyboardEvent).key === 'Shift')
  ) {
    return;
  }

  closeAction();
};

const ListItemLink = React.forwardRef((props: any, ref) => {
  return (
    <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
  );
});

const MainLink: React.FC<{ text: string, Icon?: React.ComponentType<IconProps>; href: string; }> = ({ text, Icon, ...props }) => {
  const classes = useStyles();

  return (
    <ListItem button component={ListItemLink} key={text} className={classes.sidebarMainLink} {...props}>
      <ListItemIcon className={classes.sidebarMainLinkIcon}>
        {Icon && (<Icon size={20} strokeWidth={1.5} />)}
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ className: classes.sidebarMainLinkText }} />
    </ListItem>
  );
};

export default function ResponsiveDrawer({ open = false, mobile = false }: { open?: boolean; mobile?: boolean; }) {
  const classes = useStyles();
  const { actions: UIActions } = useUIContext();

  console.log('drawer');

  return (

    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant={mobile ? 'temporary' : 'persistent'}
        open={open}
        onClose={closeNavbar(() => UIActions.toggleDrawer(false))}
      >
        <div>
          <div className={classes.logoWithMenu}>
            <IconButton color="inherit" component="span" onClick={() => UIActions.toggleDrawer()}>
              <Menu size={20} />
            </IconButton>
            <div className={classes.logoWithMenu__Link}>
              <b>OmniData</b>
            </div>
          </div>

          <Divider className={classes.sidebarDivider} />
          <List>
            <MainLink href="/" text="Strona główna" Icon={Home} />
            <MainLink href="/cards" text="Karty" Icon={CheckSquare} />
            <MainLink href="/forms" text="Formularze" Icon={Book} />
          </List>
        </div>
      </Drawer>
    </nav>

  );
}