import { AppBar, Avatar, IconButton, makeStyles, MenuItem, Toolbar, Typography, Menu, useTheme } from "@material-ui/core";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Menu as MenuIcon } from "react-feather";
import { useUIContext } from "../../context/UIContext";
import ResponsiveDrawer from "../ResponsiveDrawer";

const useStyles = makeStyles((theme) => ({
  Panel__Content: {

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${260}px)`,
      marginLeft: 260,
    },

    '&.full': {
      width: `100%`,
      marginLeft: 0,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }
  },

  Panel__AppBar: {
    background: 'rgb(255, 255, 255)',
    color: 'rgb(158, 158, 158)'
  },

  Avatar: {
    width: 30,
    height: 30,
    fontSize: 14
  },

  AvatarButton: {
    marginLeft: "auto",
    padding: theme.typography.pxToRem(7),
  },

  Panel_PageContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),

  }
}));

const Drawer = React.memo(ResponsiveDrawer);

const AppBarMemo = React.memo((props) => {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const { state: UIState, actions: UIActions } = useUIContext();

  return (
    <AppBar position="sticky" elevation={0} className={classes.Panel__AppBar}>
      <Toolbar variant="dense">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => UIActions.toggleDrawer()}>
          <MenuIcon size="20" />
        </IconButton>

        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls="accountMenu"
          aria-haspopup="true"
          onClick={(e) => { setMenuAnchor(e.currentTarget); }}
          color="inherit"
          className={classes.AvatarButton}
        >
          <Avatar className={classes.Avatar}>ŁS</Avatar>
        </IconButton>
      </Toolbar>

      <Menu
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={7}
        id="accountMenu"
        keepMounted
        open={!!menuAnchor}
        onClose={() => { setMenuAnchor(null); }}
      >
        <MenuItem>Wyloguj</MenuItem>
      </Menu>
    </AppBar>
  );
});

const PanelLayout: React.FC<any> = (props: any) => {
  const { state: UIState, actions: UIActions } = useUIContext();
  const classes = useStyles();
  const theme = useTheme();
  const [isMobileMode, setMobileMode] = useState<boolean>(false);

  //@ts-ignore
  const InnerPageContentWrapper = props?.children?.WrapperComponent ? props.children.WrapperComponent : ({ children }) => {
    return (
      <div className={classes.Panel_PageContent}>{children}</div>
    );
  };

  useEffect(() => {
    setMobileMode(window.innerWidth <= theme.breakpoints.values.lg);
  }, []);

  return (
    <div className="Panel">
      <div className="Panel__Drawer">
        <Drawer open={UIState.drawerOpened} mobile={isMobileMode} />
      </div>

      <div className={`${classes.Panel__Content} ${!UIState.drawerOpened ? 'full' : ''}`}>
        <AppBarMemo />

        <div className="Panel__PageContentContainer">
          <InnerPageContentWrapper>{props.children}</InnerPageContentWrapper>
        </div>
      </div>

    </div>
  );
};

export default PanelLayout;