import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid, IconButton } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon, Trash as DeleteIcon } from "react-feather";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import OperationElements from "./OperationElements";
import _ from "lodash";

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1,
    '&:hover': {
      // borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },

  content: {
    margin: '8px 0',

    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '8px 16px',
  },
})(MuiAccordionDetails);

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;

function Operation(props: any) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [contentVisible, setContentVisible] = useState<boolean>(expanded);


  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  const [title, setTitle] = useState<string>(getFormValue('title'));

  const refreshTitle = useCallback(_.debounce((v) => {
    setTitle(v);
  }, 150), []);

  return (
    <Accordion
      elevation={1}
      expanded={expanded}
      onChange={(e, expanded) => {
        setExpanded(expanded);
        expanded && setContentVisible(expanded);
      }}
      TransitionProps={{
        onExited: () => { setContentVisible(false); }
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
        <MuiButton
          aria-label="Acknowledge"
          onClick={(e: any) => {
            e.stopPropagation();
            setFormValue('root', 'remove');
          }}
          onFocus={(e: any) => e.stopPropagation()}
          component="button"
          size="small"
          color="secondary"
          variant="contained"
          style={{
            width: 30,
            height: 30,
            minWidth: 0,
            borderRadius: '50%',
            padding: 0,
            marginLeft: 'auto'
          }}
        >
          <DeleteIcon size={18} />
        </MuiButton>
      </AccordionSummary>

      <AccordionDetails>
        {contentVisible && (
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <TextField
                  label="Nazwa operacji"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  defaultValue={getFormValue('title')}
                  onChange={(e) => {
                    setFormValue('title', e.target.value);
                    refreshTitle(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item >
              <Grid item xs={12} >
                <Typography variant="subtitle1">Elementy</Typography>
              </Grid>

              <Grid item xs={12} >
                <OperationElements
                  elements={props.data.elements || []}
                  formKey={`${props.formKey}.elements`}
                  setFormValue={props.setFormValue}
                  getFormValue={props.getFormValue}
                />
              </Grid>

              <Grid item xs={12} >
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<PlusIcon>send</PlusIcon>}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormValue('elements', 'append');
                  }}
                  size="small"
                >
                  Nowy element
                </MuiButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(Operation);