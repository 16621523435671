
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
  }
}

const theme = createMuiTheme({

  palette: {

    background: {
      default: "#f4f6f8"
    },

    primary: {
      main: "#376fd0"
    },


  },

  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),

    button: {
      fontSize: "13px",
      textTransform: 'none'
    },

    h5: {
      fontSize: "21px",
      fontWeight: 500
    },

    h6: {
      fontSize: "18px"
    }
  },

  overrides: {
    MuiDrawer: {
      paper: {
        background: "rgb(35, 48, 68)"
      }
    },

    MuiToolbar: {
      dense: {
        minHeight: 45,
        paddingBottom: 1
      }
    }
  },

});

// Shadows
theme.shadows[1] = "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px";

export default theme;