import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, CardContent, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { useUIContext } from "../../context/UIContext";
import { Plus as PlusIcon, List as ListIcon } from "react-feather";
import DataGrid from "../../components/DataGrid/DataGrid";
import Breadcrumbs from "../../components/Breadcrumbs";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  }
}));

const NavigationLink = React.forwardRef((props: any, ref) => {
  return (
    <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
  );
});

async function fetchData() {
  // Default options are marked with *
  const response = await fetch(process.env.REACT_APP_API_URL + '/db/forms', {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });

  return response.json(); // parses JSON response into native JavaScript objects
}

export default function FormsHome(props: RouteComponentProps) {
  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'title',
      },

      {
        Header: 'Kolumna X',
        accessor: 'colX1',
      },

      {
        Header: 'Kolumna X',
        accessor: 'colX2',
      },

      {
        Header: 'Kolumna X',
        accessor: 'colX3',
      }
    ],
    []
  );

  const [data, setData] = React.useState(
    React.useMemo(() => [{}], [])
  );
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        const arr: any[] = [];

        _.each(await fetchData(), (record) => {
          arr.push(record);
        });

        if (isMounted) {
          setData(arr);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => { isMounted = false; };
  }, []);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <Breadcrumbs title="Formularze" />


      <Button
        component={NavigationLink}
        variant="contained"
        color="primary"
        startIcon={<PlusIcon>send</PlusIcon>}
        href="/forms/create"
      >
        Dodaj
      </Button>

      <Button
        component={NavigationLink}
        variant="contained"
        color="primary"
        startIcon={<ListIcon>send</ListIcon>}
        href="/forms/filled"
        style={{ marginLeft: 10 }}
      >
        Wypełnione
      </Button>

      <Card style={{ marginTop: 12 }}>
        <DataGrid
          columns={columns}
          data={data}
          setData={setData}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          rowOnClick={(row: any, index: any, event: any) => {
            navigate(`/forms/${row.original.title}`);
          }}
        />
      </Card>
    </>
  );
}