import { FormControl, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import React, { FormEvent, useEffect, useState } from "react";
import Button from "../../components/Button";
import LogoImage from "../../assets/images/logo.png";
import { navigate, RouteComponentProps } from "@reach/router";
import { useUIContext } from "../../context/UIContext";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px auto",
    maxWidth: "520px",
    display: "flex",
    minHeight: "calc(100vh - 50px)",
    marginTop: "50px",
    flexFlow: "column"
  },

  card: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5)
    },
  }
}), { name: "Auth" });

export default function Login(props: RouteComponentProps) {
  const classes = useStyles();
  const [inProgress, setInProgress] = useState<boolean>(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInProgress(true);

    setTimeout(() => navigate(`/cards`), 500);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignContent: "center", justifyContent: "center", marginBottom: "24px" }}>
        <img src={LogoImage} width={92} height={92} alt="" />
      </div>

      <Paper className={classes.card}>

        <form action="#" onSubmit={onSubmit}>

          <Typography align="center" variant="h6" component="h1">
            Zaloguj się
          </Typography>

          <FormControl fullWidth margin="normal">
            <TextField label="Login" variant="outlined" size="small" required />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField type="password" label="Hasło" variant="outlined" size="small" required />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Button inProgress={inProgress} type="submit" variant="contained" color="primary" disableElevation fullWidth>
              Zaloguj
            </Button>
          </FormControl>

        </form>

      </Paper>
    </div>
  );
}

Login.Layout = React.Fragment;