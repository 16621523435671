import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link } from "@reach/router";
import React, { useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon } from "react-feather";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    flexGrow: 1,
    '&:hover': {
      // borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },

  content: {
    margin: '8px 0',

    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '8px 16px',
  },
})(MuiAccordionDetails);

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;

const TypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('type') || 10);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Typ"
      value={value}
      onChange={(e) => {
        setFormValue('type', e.target.value);
        setValue(e.target.value);
      }}
    >
      <MenuItem value={10}>Prawda/fałsz</MenuItem>
      <MenuItem value={20}>Tekst</MenuItem>
    </Select>
  );
};

const ZW = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(!!(getFormValue('zw')));

  return (
    <Checkbox
      name="checkedB"
      color="primary"
      checked={value}
      onChange={(e) => {
        setFormValue('zw', e.target.checked);
        setValue(e.target.checked);
      }}
    />
  );
};

export default function OperationElementEntry(props: any) {

  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  return (
    <Box style={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item lg={2}>
          <TextField
            label="Nazwa wpisu"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={getFormValue('title')}
            onChange={(e) => {
              setFormValue('title', e.target.value);
            }}
          />
        </Grid>

        <Grid item lg={1}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Typ</InputLabel>
            <TypeSelect
              getFormValue={getFormValue}
              setFormValue={setFormValue}
            />
          </FormControl>
        </Grid>

        <Grid item lg={"auto"}>
          <FormControlLabel
            control={
              <ZW
                getFormValue={getFormValue}
                setFormValue={setFormValue}
              />
            }
            label="Z/W"
            title="Zakres/wartość"
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            label="Wartość/zakres #1"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={getFormValue('w1')}
            onChange={(e) => {
              setFormValue('w1', e.target.value);
            }}
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            label="Wartość/zakres #2"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={getFormValue('w2')}
            onChange={(e) => {
              setFormValue('w2', e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}