import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { UIContextProvider, useUIContext } from './context/UIContext';
import theme from './theme/MuiTheme';
import { Router, useLocation } from "@reach/router";

import Login from './Pages/auth/login';
import Dash from './Pages/index';
import MainLayoutProvider from './components/Layouts/MainLayoutProvider';
import MainRoute from './components/MainRoute';

import CardsHome from './Pages/Cards/Home';
import CardsCreate from './Pages/Cards/Create';
import FormsHome from './Pages/Forms/Home';
import FormsCreate from './Pages/Forms/Create';
import FormFilledHome from './Pages/FormsFilled/Home';
import FormsFilledCreate from './Pages/FormsFilled/Create';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UIContextProvider>
        <MainLayoutProvider>
          <Router>
            <MainRoute path="/auth/login" component={<Login />} />
            <MainRoute path="/" component={<Dash />} />

            <MainRoute path="/cards" component={<CardsHome />} />
            <MainRoute path="/cards/create" component={<CardsCreate />} />
            <MainRoute path="/cards/:name" component={<CardsCreate />} />

            <MainRoute path="/forms" component={<FormsHome />} />

            <MainRoute path="/forms/filled" component={<FormFilledHome />} />
            <MainRoute path="/forms/filled/create" component={<FormsFilledCreate />} />
            <MainRoute path="/forms/filled/:name" component={<FormsFilledCreate />} />

            <MainRoute path="/forms/create" component={<FormsCreate />} />
            <MainRoute path="/forms/:name" component={<FormsCreate />} />

          </Router>
        </MainLayoutProvider>
      </UIContextProvider>
    </ThemeProvider>
  );
}

export default App;
