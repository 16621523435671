import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Button as MuiButton, Card, CardActions, CardContent, Divider, ExtendButtonBase, List, ListItem as MuiListItem, ListItemTypeMap, Paper, Tab, Tabs, Typography, withStyles, Link as MuiLink, TextField, Grid } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Link } from "@reach/router";
import React, { useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon } from "react-feather";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import FormElement from "./FormElement";

const ListItem = withStyles({
  root: {
    padding: '0',
    '&:not(:last-child)': {
      paddingBottom: 8
    }
  }
})(MuiListItem) as OverridableComponent<ListItemTypeMap<{ button?: false; }, 'li'>> & ExtendButtonBase<ListItemTypeMap<{ button: true; }, 'div'>>;

export default function FormElementsList(props: any) {
  return (
    <List style={{ paddingTop: 0 }}>
      {
        props.elements.map((element: any, index: any) => (
          <ListItem key={index}>
            <FormElement
              data={element}
              formKey={`elements[${index}]`}
              getFormValue={props.getFormValue}
              setFormValue={props.setFormValue}
              expanded={element.new || false}
            />
          </ListItem>
        ))
      }
    </List>
  );
}